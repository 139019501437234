var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { attrs: { id: "header" } }, [
    _c(
      "span",
      { staticClass: "flex-item-logo" },
      [
        _c("router-link", { staticClass: "logo", attrs: { to: "/" } }, [
          _c("img", {
            staticClass: "logo-img",
            attrs: { src: require("@/assets/img/logo.png") },
          }),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }